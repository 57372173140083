<template>
    <div>
        <b-row class="mb-4">
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-col>
                            <h4>Exception Logger</h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                                <label>Description</label>
                                <b-form-input v-model="search.description"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row><b-col cols="4">
                        <label>Logged On From</label>
                        <b-form-datepicker v-model="search.loggedOnFrom"></b-form-datepicker>
                    </b-col>
                    <b-col cols="4">
                        <label>Logged On To</label>
                        <b-form-datepicker v-model="search.loggedOnTo"></b-form-datepicker>
                    </b-col></b-row>
                    <hr class="mx-3" />
                    <b-row>
                        <b-col cols="12" class="text-right">
                            <b-button variant="red" squared class="mr-2" @click="clearFilter">Cancel</b-button>
                            <b-button variant="primary" squared @click="searchResults">Search</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-row>
                        <b-table striped hover :items="tableData.dataSource"
                                 :fields="tableData.tableColumns"
                                 :busy="tableData.isLoading"
                                 :per-page="tableData.resultsPerPage"
                                 id="xlogTable"
                                 :current-page="tableData.currentPage" sort-icon-left>
                            <template #table-busy>
                                <div class="text-center my-2">
                                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                                </div>
                            </template>
                            
                            <template #cell(name)="row">
                                <b-row align-v="center">
                                    <span class="status-good mr-2" v-if="row.item.published === true"></span>
                                    <span class="status-bad mr-2" v-if="row.item.published === false"></span>
                                    <span class="mr-auto">{{row.item.name}}</span>
                                </b-row>
                            </template>
                            
                            <template #cell(loggedOnDate)="row">
                                <b-row align-v="center">
                                    <span class="mr-auto">{{row.item.loggedOnDate | dateTimeFilter}}</span>
                                </b-row>
                            </template>

                            
                            <template>
                                <b-row align-v="center" align-h="end">
                                    <b-button class="btn-icon" size="sm">
                                        <b-icon-chevron-right></b-icon-chevron-right>
                                    </b-button>
                                </b-row>
                            </template>

                        </b-table>
                    </b-row>
                    <b-row align-h="center" >
                            <b-pagination
                                v-model="tableData.currentPage"
                                :total-rows="rows"
                                :per-page="tableData.resultsPerPage"
                                aria-controls="xlogTable"
                            ></b-pagination>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapActions, mapMutations} from 'vuex'

export default {
    name: 'Logger',
    data: () => ({
        search:{
            description: null
        },
        tableData: {
            resultsPerPage: 10,
            currentPage: 1,
            dataSource: [],
            isLoading: true,
            tableColumns: [
                {
                    label: 'Description',
                    key: 'level',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Date Created',
                    key: 'loggedOnDate',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'Message',
                    key: 'message',
                    sortable: true,
                    tdClass: ''
                },
                {
                    label: 'AdditionalInfo',
                    key: 'additionalInfo',
                    sortable: true,
                    tdClass: ''
                    //todo: take out Nextec.Mobile.API.Managers
                }
            ]
        }
    }),
    created(){
      this.tableData.isLoading = false
      this.searchResults()
        this.setBreadcrumb([
        {
            text: 'Admin'
        },
        {
            text: 'Exception Logger',
            active: true
        }
        ])
    },
    methods:{
      ...mapMutations('breadcrumbService', ['setBreadcrumb']),
      ...mapActions(["searchXLog"]),
      clearFilter(){
            this.search = {
                description: null
            }
            this.searchResults()
        },
      
      searchResults() {
        this.tableData.isLoading = true
        
        let request = []
        
        // if (this.search.description !== null) {
        //   request.push({"key": "level", "value": this.search.description})
        // }
        var fromdate = new Date();
        var toDate = new Date(); 
        //var description = "";
        //check if date is selected
        if (!this.search.loggedOnFrom || !this.search.loggedOnTo)
        {
        
            var today = new Date();
            //console.log(today);
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            fromdate = yyyy + '-' + mm + '-' + dd + ' 00:00:00';
            toDate = yyyy + '-' + mm + '-' + dd + ' 23:59:59';
            //console.log(fromdate);
            //console.log(toDate);

        }
        else{
            //console.log(this.search.loggedOnFrom +  ' 00:00:00');
            //console.log(this.search.loggedOnTo+ ' 23:59:59');
            fromdate =this.search.loggedOnFrom +  ' 00:00:00';
            toDate =this.search.loggedOnTo+ ' 23:59:59';
        }
        
        request.push({"key":"LoggedOnDateFrom","value":fromdate})
        request.push({"key":"LoggedOnDateTo","value":toDate})
        if(this.search.description)
            request.push({"key":"Description","value":this.search.description})
        request.push({"key":"Level","value":"Error"})

        this.$store.commit('setSearchXLogRequest', request)
        this.searchXLog()
        .then((response) => {
          this.tableData.dataSource = response.data
          
          this.tableData.isLoading = false
        })
        
      },
    },
    computed:{
      rows() {
        return this.tableData.dataSource.length
      },
    },
}
</script>
